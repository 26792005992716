<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationForm"
            title="Basic Information"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Name"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      type="text"
                      placeholder="Name"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date of Birth"
                    label-for="dob"
                  >
                    <b-form-datepicker
                      id="dob"
                      v-model="dob"
                      type="text"
                      placeholder="Date of Birth"
                      required
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Phone"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="phone"
                      type="tel"
                      placeholder="Phone"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Present Address"
                    label-for="address"
                  >
                    <b-form-textarea
                      id="address"
                      v-model="address"
                      placeholder="Present Address"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Permanent Address"
                    label-for="addressPermanent"
                  >
                    <b-form-textarea
                      id="addressPermanent"
                      v-model="addressPermanent"
                      placeholder="Permanent Address"
                      required
                    />
                    <b-button
                      :disabled="!address"
                      variant="flat-primary"
                      size="sm"
                      class="mt-25"
                      @click="addressPermanent = address"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="CopyIcon"
                      /> Same as Present Address
                    </b-button>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-row>
                    <b-col cols="auto"><h6 class="mt-25">
                      Qualifications
                    </h6></b-col>
                    <b-col>
                      <hr></b-col>
                  </b-row>
                  <b-row
                    v-for="(qualification, index) in qualifications"
                    :key="index"
                    class="mb-2"
                  >
                    <b-col md="6">
                      <b-form-group
                        label="Type"
                        :label-for="`qualificationType-${index + 1}`"
                      >
                        <b-form-input
                          :id="`qualificationType-${index + 1}`"
                          v-model="qualification.type"
                          placeholder="B.Tech, MBA, Certification etc."
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Stream"
                        :label-for="`qualificationStream-${index + 1}`"
                      >
                        <b-form-input
                          :id="`qualificationStream-${index + 1}`"
                          v-model="qualification.stream"
                          placeholder="CS, Finance, Entrepreneurship etc."
                        />
                      </b-form-group>
                    </b-col><b-col
                      v-if="qualifications.length > 1"
                      sm="auto"
                    >
                      <b-button

                        class="mt-2"
                        variant="outline-danger"
                        @click="qualifications.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Institute"
                        :label-for="`qualificationInstitute-${index + 1}`"
                      >
                        <b-form-input
                          :id="`qualificationInstitute-${index + 1}`"
                          v-model="qualification.institute"
                          placeholder="Enter institute name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Upload Document"
                        :label-for="`qualification-${index + 1}`"
                      >
                        <b-form-file
                          :id="`qualification-${index + 1}`"
                          v-model="qualification.file"
                          accept="image/jpeg, image/png, image/gif"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-button

                    variant="outline-primary"
                    class="mb-2"
                    @click="qualifications.push({
                      type: '',
                      file: '',
                    })"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="PlusIcon"
                    />
                    <span>Add Qualification</span>
                  </b-button>
                </b-col>
                <b-col md="12">
                  <b-row>
                    <b-col cols="auto"><h6 class="mt-25">
                      Identity Documents
                    </h6></b-col>
                    <b-col>
                      <hr></b-col>
                  </b-row>
                  <b-row
                    v-for="(doc, index) in idDocs"
                    :key="index"
                  >
                    <b-col md="3">
                      <b-form-group
                        label="ID Type"
                        :label-for="`Doctype-${index + 1}`"
                      >
                        <v-select
                          :id="`Doctype-${index + 1}`"
                          v-model="doc.type"
                          taggable
                          push-tags
                          :options="['Aadhaar', 'Voter ID']"
                          label="Document Type"
                          placeholder="Select/Enter ID type"
                          append-to-body
                        />
                      </b-form-group>
                    </b-col>
                    <b-col><b-form-group
                      label="Upload Document"
                      :label-for="`doc-${index + 1}`"
                    >
                      <b-form-file
                        :id="`doc-${index + 1}`"
                        v-model="doc.file"
                        accept="image/jpeg, image/png, image/gif"
                      />
                    </b-form-group></b-col>
                    <b-col sm="auto">
                      <b-button
                        v-if="idDocs.length > 1"

                        class="mt-2"
                        variant="outline-danger"
                        @click="idDocs.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="index === idDocs.length - 1"

                        class="ml-2 mt-2"
                        variant="outline-primary"
                        @click="idDocs.push({
                          type: '',
                          file: '',
                        })"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Doc</span>
                      </b-button>

                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Team Information tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Team Information"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Team Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Member Role"
                    label-for="role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Member Role"
                      rules=""
                    >
                      <v-select
                        id="role"
                        v-model="role"
                        multiple
                        :options="['Role 1', 'Role 2']"
                        label="Member Role"
                        placeholder="Select Member Role(s)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Designation"
                    label-for="designation"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Designation"
                      rules=""
                    >
                      <b-form-input
                        id="designation"
                        v-model="designation"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Assign Reporting Officer"
                    label-for="reportingOfficer"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Reporting Officer"
                      rules=""
                    >
                      <v-select
                        id="reportingOfficer"
                        v-model="reportingOfficer"
                        :options="['Import from team']"
                        label="Assign Reporting Officer"
                        placeholder="Assign Reporting Officer"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Remuneration Model"
                    label-for="remunerationModel"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Remuneration Model"
                      rules=""
                    >
                      <v-select
                        id="remunerationModel"
                        v-model="remunerationModel"
                        multiple
                        :options="['Remuneration Model 1', 'Remuneration Model 2']"
                        label="Remuneration Model"
                        placeholder="Select Remuneration Model(s)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Total CTC"
                    label-for="ctc"
                  >
                    <b-form-input
                      id="ctc"
                      v-model="ctc"
                      type="number"
                      placeholder="Total Cost to Company"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Total Benefits"
                    label-for="benefits"
                  >
                    <b-form-input
                      id="benefits"
                      v-model="benefits"
                      type="text"
                      placeholder="Total Benefits"
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Documentation tab  -->
          <tab-content
            :before-change="validationFormAddress"
            title="Documentation"
          >
            <validation-observer
              ref="addressRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Documentation
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(doc, index) in docs"
                    :key="index"
                  >
                    <b-col md="3">
                      <b-form-group
                        label="Document Title"
                        :label-for="`Doctype-${index + 1}`"
                      >
                        <b-form-input
                          :id="`Doctype-${index + 1}`"
                          v-model="doc.title"
                          type="text"
                          placeholder="Document Title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col><b-form-group
                      label="Upload Document"
                      :label-for="`IDdoc-${index + 1}`"
                    >
                      <b-form-file
                        :id="`IDdoc-${index + 1}`"
                        v-model="doc.file"
                        accept="image/jpeg, image/png, image/gif"
                      />
                    </b-form-group></b-col>
                    <b-col sm="auto">
                      <b-button
                        v-if="docs.length > 1"

                        class="mt-2"
                        variant="outline-danger"
                        @click="docs.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="index === docs.length - 1"

                        class="ml-2 mt-2"
                        variant="outline-primary"
                        @click="docs.push({
                          title: '',
                          file: '',
                        })"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Doc</span>
                      </b-button>

                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BButton, BFormFile, BFormGroup, BFormInput, BRow, BFormDatepicker, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import gql from 'graphql-tag'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    vSelect,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      inputVariables: {
        qualificationsTable: [],
      },
      name: null,
      dob: null,
      qualifications: [
        {
          type: null,
          stream: null,
          institute: null,
          file: null,
        }],
      address: null,
      addressPermanent: null,
      email: null,
      phone: null,
      idDocs: [
        {
          type: null,
          file: null,
        },
      ],
      role: null,
      designation: null,
      reportingOfficer: null,
      remunerationModel: null,
      ctc: null,
      benefits: null,
      docs: [
        {
          title: null,
          file: null,
        },
      ],
      required,
    }
  },
  methods: {
    formSubmitted() {
      this.addTeam()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    addTeam() {
      this.setVariables()
      this.$apollo.mutate({
      // Query
        mutation: gql`mutation (
            $name: String!,
            $dob: date!,
            $email: String!,
            $contact_number: String!,
            $residential_add: String!,
            $permanent_add: String!,
            $role: String!,
            $designation: String!,
            $reporting_officer_id: Int!,
            $remuneration_model: String!,
            $ctc: String!,
            $benifits: String!,
            $qualificationInput: [teams_qualificationtable_insert_input!]!,
            $docs: [teams_documentationtable_insert_input!]!,
            $id: [teams_idtable_insert_input!]!,
        ) {
          insert_teams_basicinfo(objects: {
            name: $name,
            dob: $dob,
            email: $email,
            contact_number: $contact_number,
            residential_add: $residential_add,
            permanent_add: $permanent_add,
            role: $role,
            designation: $designation,
            reporting_officer_id: $reporting_officer_id,
            remuneration_model: $remuneration_model,
            ctc: $ctc,
            benifits: $benifits,
            teams_qualificationtables: {data:$qualificationInput},
            teams_documentationtables: {data: $docs},
            teams_idtables:{data:$id },
          })
          {
            returning{
              id
            }
          }
        }`,
        variables: {
          name: this.name,
          dob: this.dob,
          email: this.email,
          contact_number: this.phone,
          residential_add: this.address,
          permanent_add: this.addressPermanent,
          role: this.role.toString(),
          designation: this.designation,
          reporting_officer_id: this.reportingOfficer,
          remuneration_model: this.remunerationModel.toString(),
          ctc: this.ctc,
          benifits: this.benefits,
          qualificationInput: this.inputVariables.qualificationsTable,
          docs: this.docs,
          id: this.idDocs,
        },
      })
      this.resetValues()
      return this.error
    },
    setVariables() {
      for (let i = 0; i < this.qualifications.length; i += 1) {
        this.inputVariables.qualificationsTable.push({
          type: this.qualifications[i].type,
          stream: this.qualifications[i].stream,
          institute_name: this.qualifications[i].stream,
          file: this.qualifications[i].file.toString(),
          title: 'new title',
        })
      }
    },
    resetValues() {
      this.inputVariables.qualificationsTable = []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
